// Toggle global options
$enable-gradients: false;
$enable-shadows: true;
$offcanvas-box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
// Customize some defaults
$font-family-sans-serif: 'Outfit', sans-serif;
$headings-font-family: 'Outfit', sans-serif;
$body-color: #454A53;
$border-radius: .4rem;

$link-color: #2C83D3;
$primary: #F8992F;
$secondary: #1F3E72;
$btn-color: #fff;
$border-color: #c0cde4;
$btn-color: #fff;
$headings-font-weight: 600;
$font-weight-bold: 600;
$font-weight-bolder: 600;
$headings-color: $secondary;


:root {
    --mv-primarytextlight: #49638D;
    --mv-primarytextlighter: #F5FAFF;
}

$font-size-base: 1.125rem;
$font-size-lg: $font-size-base * 1.1111111111111112;
$lead-font-size: $font-size-base * 1.1111111111111112;
$font-size-sm: $font-size-base * 0.8888888888888888;
$small-font-size: $font-size-base * 0.8888888888888888;
$headings-font-weight: 400;
$h1-font-size: $font-size-base * 3.3333333333333335;
$h2-font-size: $font-size-base * 2.2222222222222223;
$h3-font-size: $font-size-base * 1.7777777777777777;
$h4-font-size: $font-size-base * 1.3333333333333333;
$h5-font-size: $font-size-base * 1.1111111111111112;
$h6-font-size: $font-size-base * 1;
$headings-line-height: 1.2;

$form-label-font-size: 0.8888888888888888rem;
$form-label-margin-bottom: 4px;
$form-label-color: var(--mv-primarytextlight);

$dropdown-border-color: transparent;
$dropdown-border-width: 0;
$container-padding-x: 32px;

$accordion-padding-y:                     12px;
$accordion-padding-x:                     24px;
$accordion-color:                         $secondary;
$accordion-bg:                            transparent;
$accordion-border-width:                  none;
$accordion-button-padding-y:              8px;
$accordion-button-padding-x:              24px;
$accordion-button-bg:                     transparent;
$accordion-button-active-bg:              transparent;
$accordion-button-active-color:           $secondary;
$accordion-icon-color:                    $secondary;
$accordion-icon-active-color:             $secondary;
$accordion-button-focus-border-color:     transparent;
$accordion-button-focus-box-shadow:       none;
$accordion-body-padding-y:                8px 16px;
$accordion-button-padding-x:              16px;
// scss-docs-end font-variables

// scss-docs-start pagination-variables
$pagination-color:  $secondary;
$pagination-bg: transparent;

$pagination-focus-color:$secondary;
$pagination-focus-bg:  rgba($secondary, .15);

$pagination-hover-color: $secondary;
$pagination-hover-bg: rgba($secondary, .15);
$pagination-hover-border-color: rgba($secondary, .15);

$pagination-active-color: #fff;
$pagination-active-bg:  $secondary;;
$pagination-active-border-color: $secondary;;

// Required
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";
@import "bootstrap/scss/root";
@import "bootstrap/scss/reboot";

@import "bootstrap/scss/type";
@import "bootstrap/scss/images";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/grid";
@import "bootstrap/scss/tables";
@import "bootstrap/scss/forms";
@import "bootstrap/scss/buttons";
@import "bootstrap/scss/transitions";
@import "bootstrap/scss/dropdown";
@import "bootstrap/scss/button-group";
@import "bootstrap/scss/nav";
@import "bootstrap/scss/navbar"; // Requires nav
@import "bootstrap/scss/breadcrumb";
@import "bootstrap/scss/accordion";
@import "bootstrap/scss/pagination";
@import "bootstrap/scss/alert";
@import "bootstrap/scss/badge";
@import "bootstrap/scss/progress";
@import "bootstrap/scss/list-group";
@import "bootstrap/scss/close";
@import "bootstrap/scss/toasts";
@import "bootstrap/scss/modal"; // Requires transitions
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/popover";
@import "bootstrap/scss/carousel";
@import "bootstrap/scss/spinners";
@import "bootstrap/scss/offcanvas"; // Requires transitions
@import "bootstrap/scss/placeholders";

// Helpers
@import "bootstrap/scss/helpers";

// Utilities
@import "bootstrap/scss/utilities/api";

// Custom styles
@import "pxtorem";

// Components
@import "components/mv-c-header";
@import "components/mv-c-nav";
@import "components/mv-c-mmenu";
@import "components/mv-c-searchtab";
@import "components/mv-c-quickinfo";
@import "components/mv-c-footer";
@import "components/mv-c-sociallinks";
@import "components/mv-c-rating";
@import "components/mv-c-badge";
@import "components/mv-pagination";
@import "components/mv-c-accordion";

// page
@import "pages/common";
@import "pages/form";
@import "pages/home";
@import "pages/list";
@import "pages/grid";
@import "pages/card";
@import "pages/icon";
@import "pages/filter";
@import "pages/buy-rent";
@import "pages/counter";
@import "pages/news";
@import "pages/contact";
@import "pages/aboutus";
@import "pages/property-manage";
@import "pages/agent-details";
@import "pages/find-landing";

// Banner
@import "banner/mv-b-homebanner";
@import "banner/mv-b-searchbanner";

// Articles
@import "articles/mv-a";
@import "articles/mv-a--welcome";
@import "articles/mv-a--quickinfo";
@import "articles/mv-a--news";
@import "articles/mv-a--testimonial";
@import "articles/mv-a--featured";
@import "articles/mv-a--meetagents";
@import "articles/mv-a--sell";

// Sections
@import "sections/mv-s-newsletter";
@import "sections/mv-s-sendmsg";

// Plugins
@import "plugins/owl-carousel";
@import "plugins/nouislider";