.mv-badge {
    display: inline-flex;
    vertical-align: middle;
    height: 24px;
    padding: 4px 8px;
    align-items: center;
    border-radius: 4px;
    font-size: rem(14px);
    font-weight: 600;
    &--white {
        background-color: $white;
        color: $secondary;
    }
    &--secondary {
        background-color: $secondary;
        color: $white;
    }
    &--primary {
        background-color: $primary;
        color: $white;
    }
}