.mv-c-sociallinks {
    display: flex;
    flex-direction: column;
    &--hrizontal {
        flex-direction: row;
        gap: 16px;
        align-items: center;
    }
    small {
        color: $secondary;
    }
}
.mv-c-sociallinks_icons {
    display: flex;
    gap: rem(16px);
}
.mv-c-sociallinks_icon {
    width: rem(32px);
    height: rem(32px);
    border-radius: 100%;
    padding: rem(8px);
    color: $white;
    display: block;
    color: $white;

    &--facebook {
        background-color: #3F62A9;
    }
    &--in {
        background-color: #1F3E72;
    }
    &--youtube {
        background-color: #F20000;
    }
    &--x {
        background-color: #000;
    }
    &--web {
        background-color: #29ca9d;
    }
}