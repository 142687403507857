.mv-c-header {
    display: flex;
    align-items: center;
    padding: rem(24px);
    min-height: rem(74px);

    * {transition: all .5s;}    

    .navbar {        
        border-bottom: 1px solid rgba(255, 255, 255, 0);
    }

    .mv-c-nav {
        margin-right: auto;
        .dropdown {
            .dropdown-menu {
                min-width: 234px;
            }
        }
    }

    .navbar-brand {
        margin-right: rem(80px);
    }

    &__contact,
    &__srbcontact {
        display: flex;
        gap: 24px;
    }
}

.mv-c-header__toggle {
    display: flex;
    flex: 1;
    align-items: center;
    position: relative;

    .navbar-toggler {
        margin-left: auto;
    }
}

.mv-c-header--sticky {
    transition: all .5s;
    >.navbar:before {
        content: "";
        height: 100%;
        position: absolute;
        left: 0;
        right: 0;
        background-color: rgba(255, 255, 255, .8);
        -webkit-backdrop-filter: blur(24px);
        backdrop-filter: blur(24px);
        border-bottom: 1px solid $white;
        // // background-color: $white;
        transition: all .5s;
        box-shadow: 0px 16px 16px 0px rgba(176, 194, 211, .2);
        z-index: -1;
        transition: all .5s;
    }

    .navbar-brand {

        img,
        svg {
            width: 90px;
        }
    }
}

.mv-c-phone {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-end;

    * {
        font-size: 0.875rem;
        color: $secondary;
    }

    span {
        color: var(--mv-primarytextlight);
    }

    .mv-c-phone__call {
        font-weight: 600;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
}

@include media-breakpoint-down(xl) {
    .mv-c-header__toggle {
        .navbar-collapse {
            position: fixed;
            left: -250px;
            top: 0;
            background: #fff;
            border-radius: 0;
            bottom: 0;
            width: 250px;
            z-index: 100;
            display: flex;
            flex-direction: column;
            align-items: initial;
            transition: left .5s;
            transition-delay: 0;
            height: 100% !important;
            box-shadow: 0 0 0 rgba(0, 0, 0, .5);

            &.collapsing {
                left: 0;
                box-shadow: 0 0 120px rgba(0, 0, 0, .5);
                transition: left .5s;
                transition-delay: 0;
            }

            &.show {
                left: 0;
                box-shadow: 0 0 120px rgba(0, 0, 0, .5);
            }

            .dropdown-menu.show {
                position: relative;
                box-shadow: none;
            }
        }

        .navbar-toggler:focus {
            box-shadow: none;
        }
    }

    .mv-c-header {
        &__contact {
            display: flex;
            gap: 12px;
            flex-direction: column;
            padding: 12px 24px;
            border-top: 1px solid $border-color;
        }

        .mv-c-phone {
            align-items: flex-start;
        }

        .mv-c-nav {
            flex-direction: column;
            align-items: initial;
            padding: 24px;
            flex: 1 1 auto;
            min-height: 0;
            overflow: auto;
            margin: 0;
            width: 100%;
        }

        .mv-c-nav__link {
            font-size: 16px;
            padding: 8px 0;
            height: auto;
            display: flex;
            align-items: center;

            .icon-12 {
                margin-left: auto !important;
            }
        }

        .mv-c-nav__link.active {
            border: none;
            text-decoration: none;
        }

        .mv-c-nav .dropdown-item {
            padding: 8px;
        }

        .mv-c-nav .dropdown--wide .dropdown-menu.show {
            padding: 0;
            flex-direction: column;
            margin: 0;
        }

        .mv-c-nav .dropdown--wide .dropdown-menu>li {
            border-left: none;
        }

    }
    
    .mv-c-header {
        .fixed-top {
            background-color: #fff !important;
        }
    }
    .mv-c-nav {
        margin-right: auto;
        .dropdown {
            .dropdown-menu {
                min-width: initial !important;
                > .menu-item {
                flex-direction: column !important;
                }
            }
        }
    }
}
