.mv-propertylisting {
    &__layout {
        display: flex;
        padding: 0 0 40px;
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__advfilters {
        flex: 0 0 280px;
        border-right: 1px solid $border-color;
    }

    &__content {
        flex: 1 1 auto;
        min-width: 0;
        padding-left: 24px;
    }
}

.mv-propertylisting-filter {
    display: flex;
    gap: 16px;
    align-items: center;

    &__count {
        color: $secondary;
    }

    .mv-sort-dropdown {
        margin-left: auto;
    }
}

.mv-sort-dropdown {
    display: flex;
    align-items: center;

    &__label {
        font-size: rem(16px);
        margin-right: 4px;
        color: var(--mv-primarytextlight);
    }

    .btn {
        color: $secondary;
        font-weight: 600;
    }
}

.mv-accordion-filter__sticky {
    display: none;
}


@include media-breakpoint-up(xl) {
    .mv-propertylisting {
        &__content {
            padding-left: 60px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .mv-propertylisting__advfilters {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0,0,0,.6);
        z-index: 1050;

        &.open {
            display: flex;
        }

        .mv-accordion-filter {
            background-color: $white;
            border-radius: 4px;
            width: 100%;
            height: 100%;
            overflow: auto;
            max-width: 320px;
        }
    }

    .mv-propertylisting__content {
        padding-left: 0;
    }

    .mv-accordion-filter__sticky {
        display: flex;
        align-items: center;
        top: 0;
        background-color: #fff;
        position: sticky;
        justify-content: space-between;
        padding: 16px 16px 8px;
        border-bottom: 1px solid $border-color;
        margin-bottom: 12px;
        z-index: 1;
    }
}