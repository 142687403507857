.mv-c-quickinfo {
    @extend .list-unstyled;
    display: inline-flex;
    align-items: center;
    gap: 80px;
    text-align: left;

    &__item {
        display: flex;
        align-items: center;
        gap: 16px;
        span {
            line-height: normal;
        }
    }

    strong {
        font-size: $h2-font-size;
        color: $secondary;
        margin-bottom: 0;
    }
}

@include media-breakpoint-down(md) {
    .mv-c-quickinfo {
        flex-direction: column;
        gap: 24px;
    }
}