.mv-a {
    padding: 40px 0 80px;

    &--bg {
        position: relative;

        &::before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(180deg, rgba($white, 0) 0%, $white 29.69%, rgba($white, .89) 73.44%, rgba($white, 0) 100%);
        }

        >* {
            position: relative;
            // z-index: 1;
        }
    }

    &__layout {
        display: flex;
    }

    &__content {
        padding: 0 80px;
        flex: 1;
        min-width: 0;
    }

    &__image {
        flex: 1;
        min-width: 0;
    }

    &__footer {
        padding-top: 40px;
        text-align: center;
    }

}

.mv-a__header {
    margin-bottom: rem(32px);

    h1 {
        margin-bottom: 0;

        small {
            font-size: $h4-font-size;
            line-height: normal;
            display: block;
        }
    }

    h2 {
        margin-bottom: 0;

        +p {
            margin-top: 16px;
        }
    }

    p {
        margin-bottom: 0;
    }
}

@include media-breakpoint-down(xxl) {

    .mv-a__header h1,
    .mv-a__header .h1 {
        font-size: rem(48px);
    }
}

@include media-breakpoint-down(xl) {
    .mv-a {
        &__content {
            padding: 0 40px;
        }

        &__layout {
            gap: 24px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .mv-a {
        padding: 20px 0;

        &__layout {
            flex-direction: column;
            align-items: center;
            gap: 16px;
        }

        &__image {
            order: 1;
            max-width: 400px;
        }

        &__image-media-sm {
            max-width: 100% !important;
        }

        &__img-sm {
            max-width: 100%;
        }

        &__content {
            order: 2;
            padding: 24px 0 0;
        }

        &__content-sm-p-left {
            padding-left: 0 !important;
        }
    }
}

@include media-breakpoint-down(lg) {
    .mv-a {
        &__img-sm {
            max-width: 100%;
        }
    }
}