.mv-c-nav {
    @extend .list-unstyled;
    display: flex;
    align-items: center;
    margin: 0;

    &__link {
        display: flex;
        padding: 4px rem(20px);
        height: rem(40px);
        color: $secondary;
        text-decoration: none;
        font-size: rem(16px);
        font-weight: 600;
        align-items: center;
        border-bottom: 2px solid transparent;
        text-decoration: none !important;

        &:hover,
        &:focus {
            color: $primary;
        }

        &.active {
            border-bottom-color: $primary;
            color: $primary;
        }
    }

    .dropdown-menu>li {
        position: relative;
        display: flex;
        align-items: center;

        &:hover,
        &:focus {
            .dropdown-menu-sub {
                display: block;
            }
        }
    }

    .dropdown-item {
        text-decoration: none !important;
        position: relative;
        color: $secondary;
        font-size: rem(16px);
        padding: 8px 16px;
        display: flex;
        align-items: center;
        gap: 16px;
        font-size: 14px;
        white-space: normal;
        justify-content: space-between; 

        .icon {
            margin-left: auto;
        }
    }

    .dropdown-toggle {
        &::after {
            display: none;
        }
    }

    .dropdown--wide {
        position: static;

        >ul>li>a {
            font-weight: 600;
        }

        .dropdown-menu {
            &.show {
                display: flex;
                padding: 16px 0;
            }

            >li {
                flex-direction: column;
                border-left: 1px solid $border-color;
                flex: 1;
                min-width: 0;

                &:first-child {
                    border-left: none;
                }
            }
        }
    }
}

.dropdown-menu-sub {
    @extend .list-unstyled;
    width: 100%;
    // padding-left: 16px;
}