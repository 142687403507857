body {
    &:before {
        content: "";
        width: 100%;
        height: 100%;
        background-image: url('./../img/main-bg.jpg');
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        pointer-events: none;
        z-index: -2;
        display: block;
        position: fixed;
        top: 0;
        right: 0;
    }

    &:after {
        content: "";
        width: 637px;
        max-width: 72%;
        background-image: url("./../img/main-bg-after.png");
        background-position: right top;
        background-size: 100%;
        background-repeat: no-repeat;
        pointer-events: none;
        padding-top: 100%;
        z-index: -1;
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        opacity: .8;
    }
    &.mv-a {
        &__if-landing {
            &::after {
                display: none;
            }
        }
    }
}

.mv-figure {
    margin: 0;
    display: block;
    position: relative;
    overflow: hidden;
    border-radius: 8px;

    .img-fluid {
        width: 100%;
    }
}
.img-right-align {
float: right;
margin: 0px 0px 32px 32px;
border-radius: 12px 50px 12px 50px;
max-width: 45%;
}

.mv-time {
    color: $primary;
    font-size: $font-size-sm;
    font-weight: 600;
}

.mv-figure-thumbnails {
    display: flex;
    gap: 8px;
    padding-top: 8px;
    justify-content: center;

    .mv-figure-thumbnail {
        cursor: pointer;
        .mv-figure img {
            transform: none!important;
        }
    }
}

.mv-figure-thumbnails .mv-figure-thumbnail,
.mv-figure-thumbnail-count {
    width: rem(48px);
}

.mv-figure-thumbnails .mv-figure,
.mv-figure-thumbnail-count {
    border-radius: 4px;
}

.mv-figure-thumbnail-count {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E1EDF9;
    font-size: rem(14px);
    font-weight: 600;
    color: $secondary;
}

a:not(.btn) {
    text-decoration: none;
}

[data-bs-theme="dark"] {
    color-scheme: dark;
    --mv-primarytextlight: #222128;
    --mv-primarytextlighter: #222128;

    body {
        background: none;
    }

    body::after,
    body::before {
        filter: grayscale(100%);
        mix-blend-mode: multiply;
        opacity: .05;
    }

    .mv-a {
        &--bg {
            &::before {
                background: none;
            }
        }
    }
}

.mv-scroll-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    padding-bottom: 20px;

    img {
        height: 112px;
    }

    .text-tiny {
        text-transform: uppercase;
        color: $secondary;
        font-weight: 600;
        font-size: rem(14px);
    }
}

.svg-icon-hide {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
}

legend {
    font-size: rem(18px) !important;
}

.btn-primary {
    color: #4f2a00 !important;
}

.mv-link {
    cursor: pointer;

    &--sm {
        font-size: rem(16px);
    }
}

.mv-separator--sm {
    position: relative;
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 16px 0;

    &:before,
    &:after {
        display: block;
        content: "";
        height: 1px;
        width: 90px;

    }

    &:before {
        background: linear-gradient(90deg, rgba(192, 205, 228, 0.00) 0%, #C0CDE4 100%);
    }

    &:after {
        background: linear-gradient(90deg, #C0CDE4 0%, rgba(192, 205, 228, 0.00) 100%);
    }
}

.mv-avatar-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.mv-avatar {
    overflow: hidden;
    display: block;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    &--rounded {
        border-radius: 100%;
    }

    &--sm {
        width: 48px;
        height: 49px;

    }
}
.mv-a {
    &__img-curved {
        border-radius: 12px 50px 12px 50px; 
    }
}


:root {
    --animate-duration: 1s;
    --animate-delay: 1s;
    --animate-repeat: 1;
}

.animate__animated {
    -webkit-animation-duration: 1s;
    animation-duration: 1s;
    -webkit-animation-duration: var(--animate-duration);
    animation-duration: var(--animate-duration);
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.animate__animated.animate__infinite {
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
}

@-webkit-keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

@keyframes pulse {
    from {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }

    50% {
        -webkit-transform: scale3d(1.05, 1.05, 1.05);
        transform: scale3d(1.05, 1.05, 1.05);
    }

    to {
        -webkit-transform: scale3d(1, 1, 1);
        transform: scale3d(1, 1, 1);
    }
}

.animate__pulse {
    -webkit-animation-name: pulse;
    animation-name: pulse;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

@include media-breakpoint-down(xxl) {
    html {
        font-size: 14px;
    }
}

@include media-breakpoint-down(lg) {
    html {
        font-size: 12px;
    }

    .mv-scroll-down {
        display: none;
    }

}

@include media-breakpoint-down(md) {
    .hide-mob {
        display: none;
    }
}