.mv-c-searchtab {
    z-index: 1;
    position: relative;

    &__list {
        @extend .list-unstyled;
        display: inline-flex;
        background-color: $white;
        border-radius: 4px 4px 0px 0px;
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, .1);
    }

    &__link {
        padding: 4px 24px;
        height: rem(48px);
        font-weight: $font-weight-bolder;
        background: transparent;
        color: $secondary;
        border: none;
        border-bottom: 2px solid $white;

        &.active {
            color: $primary;
            border-bottom-color: $primary;
        }
    }

    &__content {
        padding: rem(24px);
        background-color: rgba(255,255,255,.1);
        border-radius: 0px 4px 4px 4px;
        box-shadow: 0px 40px 40px 0px rgba(176, 194, 211, 0.40);
        -webkit-backdrop-filter: blur(30px) brightness(124%);
        backdrop-filter: blur(30px) brightness(124%);

        .form-control,
        .input-group-text,
        .form-select {
            border: none;
            box-shadow: none;
            background-color: #E9EFF6;
        }

        .input-group-text {
            background-color: #E9EFF6;
        }
    }

    &__grid {
        display: flex;
        gap: 16px;

        .input-group {
            .form-control {
                
                &::-webkit-input-placeholder {
                    color: $secondary;
                    opacity: .6;
                }

                &:-ms-input-placeholder {
                    color: $secondary;
                    opacity: .6;
                }

                &::placeholder {
                    color: $secondary;
                    opacity: .6;
                }
            }

            .input-group-text {
                color: $secondary;
            }
        }

        .input-group,
        .dropdown {
            flex: 1;

            .form-control {
                border-radius: 8px 0 0 8px!important;
            }
            .form-control,
            .btn {
                font-size: rem(16px);
                color: $secondary;
                height: rem(48px);
            }
        }
    }

    &__toggle {
        background-color: #e9eff6 !important;
        border: none;
        display: flex;
        align-items: center;
        gap: 24px;
        width: 100%;

        span {
            flex: 1 1 auto;
            text-align: left;
        }

        &.show {
            .icon {
                transform: rotate(180deg);
            }
        }

        &::after {
            display: none;
        }
    }
}