.mv-a--news {
    .mv-a__layout {
        display: flex;
        gap: 12px;
    }

    &__highlight,
    &__popular {
        flex: 1;
        min-width: 0;
    }

    &__popular {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
        gap: 12px;
        
    }

    .mv-a__header {
        text-align: center;
    }
}

@include media-breakpoint-up(md) {
    .mv-a--news {
        &__popular {
            grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        }
    }
}

@include media-breakpoint-up(xl) {
    .mv-a--news {
        .mv-a__layout {
            gap: 24px;
        }
    }
}