.icon {
    svg {
        fill: currentColor;
    }
}

.icon,
.icon-8,
.icon-12,
.icon-16,
.icon-20,
.icon-24,
.icon-32,
.icon-36,
.icon-40,
.icon-60 {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.icon-8 {
    width: rem(8px);
    height: rem(8px);
}

.icon-12 {
    width: rem(12px);
    height: rem(12px);
}

.icon-16 {
    width: rem(16px);
    height: rem(16px);
}

.icon-20 {
    width: rem(20px);
    height: rem(20px);
}

.icon-24 {
    width: rem(24px);
    height: rem(24px);
}

.icon-32 {
    width: rem(32px);
    height: rem(32px);
}

.icon-36 {
    width: rem(36px);
    height: rem(36px);
}


.icon-40 {
    width: rem(40px);
    height: rem(40px);
}

.icon-60 {
    width: rem(60px);
    height: rem(60px);
}