.mv-grid {
    display: grid;
    gap: 24px;
}

.mv-grid--2,
.mv-grid--3 {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
}

.mv-grid--4 {
    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));
    gap: 8px;
}

@include media-breakpoint-up(lg) {
    .mv-grid {
        gap: 40px;
    }

    .mv-grid--2 {
        grid-template-columns: repeat(auto-fill, minmax(420px, 1fr));
    }

    .mv-grid--3 {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }

    .mv-grid--4 {
        grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    }
}