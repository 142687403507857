.mv-s-newsletter {
    &__layout {
        display: flex;
        gap: 40px;
        border-top: 4px solid $primary;
        padding: 40px 0;
        align-items: center;
    }

    &__content,
    &__form {
        flex: 1;
        min-width: 0;

        >*:last-child {
            margin-bottom: 0;
        }
    }

    &__separator {
        border-left: 1px solid $border-color;
        width: 0;
        display: block;
        margin: rem(24px) 0;
        align-self: normal;
    }
}

.mv-s-newsletter__highlight {
    @extend .list-unstyled;
    display: flex;
    gap: rem(20px);

    &__list {
        padding-right: rem(20px);
        color: $secondary;
        position: relative;

        &::after {
            content: "";
            width: 6px;
            height: 6px;
            border-radius: 8px;
            background-color: $primary;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}

.mv-s-newsletter__submit {
    display: flex;

    .form-control {
        border-radius: 4px 0 0 4px;
        box-shadow: 0px 24px 40px 0px rgba(56, 92, 153, .13);
        border: none;
    }

    .btn {
        border-radius: 0px 4px 4px 0px;
    }
}

@include media-breakpoint-down(lg) {
    .mv-s-newsletter {
        &__layout {
            flex-direction: column;
            gap: 20px;
            align-items: normal;
        }
    }

    .mv-s-newsletter__separator {
        border-left: none;
        border-bottom: 1px solid #c0cde4;
        width: 100%;
        margin: 0;
    }
}

@include media-breakpoint-down(sm) {
    .mv-s-newsletter__highlight {
        gap: 8px;
    }

    .mv-s-newsletter__highlight__list {
        padding-right: 16px;
        font-size: 12px;
    }
}