.mv-b-homebanner {
    padding-top: 120px;
    display: flex;

    >.container {
        display: flex;
        gap: 80px;
        align-items: center;
    }

    .mv-c-searchtab {
        margin-bottom: rem(48px);
        width: 760px;
    }

    &__layout {
        display: flex;
        align-items: center;
    }

    &__content,
    &__image {
        flex: 1;
        min-width: 0;
    }
}

@include media-breakpoint-up(xxl) {
    .mv-b-homebanner {
        >.container {
            max-width: 1200px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .mv-b-homebanner {
        padding-top: 40px;

        &__layout {
            gap: 24px;
            flex-direction: column;
            width: 100%;
        }

        &__image {
            display: none;
        }

        &__content {
            padding: 40px 0 0;
            width: 100%;
        }

        .mv-c-searchtab {
            width: 100%;
        }

        .mv-c-sociallinks {
            align-items: center;
            margin-bottom: 40px;
        }
    }
}

@include media-breakpoint-down(xl) {

    .mv-b-homebanner {
        .mv-c-searchtab {
            width: 100%;
            max-width: 480px;
            margin: 0 auto 24px;
        }
        .mv-a__header {
            text-align: center;
        }
    }

    .mv-b-homebanner__layout {
        display: flex;
        gap: 40px;
    }

    .mv-b-homebanner__content {
        flex: 1 1 auto;
    }

    .mv-b-homebanner__image {
        flex: 0 0 40%;
    }

    .mv-c-searchtab__grid {
        flex-direction: column;
    }
}