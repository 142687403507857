.mv-checkbox-tree {
    display: flex;
    flex-direction: column;
    gap: 8px;

    &--l1 {
        padding-left: 28px;
        margin-top: 8px;
    }

    .form-check-label {
        font-size: rem(16px);
    }

    .form-check {
        margin-bottom: 0;
    }
}

.mv-search-all {
    position: relative;

    .icon,
    .btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    .icon {
        position: absolute;
        left: 16px;
    }

    .btn {
        position: absolute;
        right: 8px;
        padding: 4px 16px;
        margin-right: -4px;
    }

    .btn,
    .form-control {
        border-radius: 60px;
    }

    .form-control {
        border: none;
        padding-left: 48px;
        font-size: rem(16px);
        padding-right: rem(100px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.form-check-group {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
}

.mv-form-group {
    margin-bottom: 24px;
}

.mv-required {
    color: $danger;
}

.form-label {
    font-weight: 600;
    display: block;
}

.mv-formcontrol-invalid {
    position: relative;

    .form-control {
        border-color: $danger;
    }

    .invalid-feedback {
        display: block;
        position: absolute;
        bottom: -20px;
        font-size: 13px;
        left: 4px;
    }
}