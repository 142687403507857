.mv-a {
    &__banner-slider {
        position: relative;
        margin-top: -74px;

        .mv-a__header h1,
        .mv-a__header .h1 {
            text-shadow: 1px 2px 0px #2b1c00, 1px 2px 50px #e1af51;
        }

        .owl-item .item {
            &::after {
                content: "";
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                z-index: 0;
                background: linear-gradient(90deg, rgba(0, 0, 0, 0.2) 0.04%, rgba(0, 0, 0, 0) 101.91%);
            }
        }

        .owl-carousel {
            max-height: calc(100vh - 127px);
            overflow: hidden;
            z-index: initial;

            .owl-nav {
                position: absolute;
                bottom: 32px;
                right: 32px;
                z-index: 2;
                display: flex;
                gap: 8px;

                button {
                    font-size: 60px !important;
                    font-weight: 300 !important;

                    &.owl-prev,
                    &.owl-next {
                        display: flex !important;
                        width: 32px;
                        height: 32px;
                        align-items: center;
                        justify-content: center;
                        border-radius: 32px;
                        padding: 1.5rem;
                        background-color: rgba(255, 255, 255, .1);
                        -webkit-backdrop-filter: blur(30px) brightness(124%);
                        backdrop-filter: blur(30px) brightness(124%);

                        span {
                            display: block;
                            font-size: 32px;
                            color: $secondary;
                            line-height: 24px;
                            height: 32px;
                        }

                        &:hover {
                            background-color: $secondary;

                            span {
                                color: $white;
                            }
                        }
                    }
                }
            }
        }
    }

    &__banner-shape-after {
        position: absolute;
        bottom: -1px;
        z-index: 1;
        left: 0;
        right: 0;

        svg {
            width: 100% !important;
        }
    }

    &__slider-h {
        max-height: calc(100vh - 127px);
        overflow: hidden;
    }

    &__banner-content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        z-index: 1;
    }

    &__image-container {
        width: 489px;
        height: 510px;
        background: linear-gradient(to bottom, rgba(222, 232, 242, 0) 0%, rgba(222, 232, 242, 1) 100%);
        border-radius: 26px;
        position: relative;
        margin-left: auto;

        .img-container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -40px;

            img {
                max-width: 85%;
            }

        }
    }

    &__description-block {
        padding-bottom: 100px;
    }

    &__f-right {
        float: right;

        img {
            border-radius: 0px 80px 0px 80px;
        }
    }
}

.mv-a__description-block {
    background: linear-gradient(to bottom, rgba(231, 243, 255, 1) 0%, rgba(231, 243, 255, 0.48) 52%, rgba(231, 243, 255, 0) 100%);
}

.floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }

    50% {
        transform: translate(0, 15px);
    }

    100% {
        transform: translate(0, -0px);
    }
}


@include media-breakpoint-down(lg) {
    .mv-a__banner-slider {
        margin-top: 18px;

        .owl-item .item {
            height: 480px;

            img {
                height: 100%;
                object-fit: cover;
            }
        }

        .mv-a__header h1,
        .mv-a__header .h1 {
            font-size: 54px;
            text-align: center;

            strong {
                display: block;
            }
        }
    }

    .mv-a__description-block {
        .mv-a__image {
            display: none;
        }
    }
}

@include media-breakpoint-down(sm) {
    .mv-a__banner-slider {
        margin-top: 18px;

        .owl-item .item {
            height: 320px;
        }

        .mv-a__header h1,
        .mv-a__header .h1 {
            font-size: 32px;
        }
    }
}