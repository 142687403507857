.mv-a {
    &--aboutus {
        .mv-a {
            &__layout {
                align-items: center;
                display: flex;
            }
        }

        .tag-img {
            border-radius: 12px 50px 12px 50px;
            overflow: hidden;
        }
    }

    &__aboutus-body {
        padding: 40px 0 0;
    }

    &__service-section {
        background: #000;
        margin: 150px 0 70px;
        padding: 60px 0;
        background-image: url(../../img/rent-banner.jpg);
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;


        &:after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background-color: rgba(#000, .8);
             
        }
        * {
            position: relative;
            z-index: 1;
        }

    }
 

    &__text-style {
        color: #fff;
        font-weight: bold;
    }

    &__p-description {
        color: #fff;
    }
    &__section-mt {
        margin: 100px 0;
    }
}

@include media-breakpoint-down(lg) { 
.mv-a {
    &__pt-sm-0 {
        padding-top: 0;
    }
}
}