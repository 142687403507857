.mv-a--testimonial {
    &::before {
        opacity: .6;
    }

    .mv-a__header {
        text-align: center;
    }

    .owl-prev,
    .owl-next {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        span {
            width: rem(24px);
            height: rem(24px);
            background-color: #f6faff;
            border-radius: 4px;
            display: block;
            line-height: rem(12px);
            border: 1px solid $secondary;
            font-size: rem(32px) !important;
        }

        &:hover,
        &:focus {
            span {
                background-color: $secondary;
                color: $white;
            }
        }
    }

    .owl-prev {
        left: -12px;
    }

    .owl-next {
        right: -12px;
    }

}

@include media-breakpoint-up(md) {
    .mv-a--testimonial {
        .owl-prev {
            left: rem(-32px);
        }

        .owl-next {
            right: rem(-32px);
        }
    }
}