.mv-a {
    &__agent-img {
        margin-bottom: 28px;
        img {
            width: 100%;
            border-radius: 60px 0px 60px 0px;
        }
    }
    &__info-loop {
        padding-bottom: 8px;
        margin-bottom: 8px;
        border-bottom: dashed 1px #ccc;
        font-size: 16px;
    }
}

@include media-breakpoint-down(lg) { 
    .mv-a {
        &__agent-details-info {
            flex-direction: column;
            .mv-a {
                &__has-sm-block {
                    display: block !important;
                    width: 100%;
                    margin-bottom: 4px !important;  
                }
                &__agent-details {
                    display: flex;
                    padding: 0;
                    width: 100%;  
                }
                &__agent-img {
                    margin-bottom: 0px;
                    width: 199px;
                    flex: 0 0 auto;
                }
                &__agent-info {
                    flex: 1 1 auto;
                    padding-left: 52px;
                }
            }
        }
      
    }
    }
    @include media-breakpoint-down(sm) { 
        .mv-a {
            &__agent-details-info {
                flex-direction: column;
                .mv-a {
                    &__has-sm-block {
                        display: block !important;
                        width: 100%;
                        margin-bottom: 4px !important;  
                    }
                    &__agent-details {
                        display: flex;
                        padding: 0;
                        width: 100%;  
                        flex-direction: column;
                    }
                    &__agent-img {
                        margin-bottom: 32px !important;
                        width: 199px;
                        flex: 0 0 auto;
                        margin: 0 auto;
                    }
                    &__agent-info {
                        flex: 1 1 auto;
                        padding-left: 0px;
                        text-align: center;
                    }
                    &__info-loop {
                        padding-bottom: 4px;
                        margin-bottom: 4px;
                        font-size: 14px;
                        display: inline-flex;
                        margin-right: 12px;
                        padding-right: 12px;
                        border-right: solid 1px #e3e3e3;
                        border-bottom: none;
                        &:last-child {
                            margin-right: 0px;
                            padding-right: 0px;
                            border-right: none;
                        }
                    }
                }
            }
          
        }
        }