.mv-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;

    a {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: rem(40px);
        height: rem(40px);
        border-radius: 4px;
        border: 1px solid $border-color;
        font-size: rem(14px);
        font-weight: 600;
        color: $secondary;
        text-decoration: none;

        &:hover,
        &:focus {
            background-color: var(--mv-primarytextlight);
            border-color: var(--mv-primarytextlight);
            color: $white;
            text-decoration: none !important;
        }

        &.active {
            background-color: $secondary;
            border-color: $secondary;
            color: $white;
        }
    }
}