.mv-card {
    display: flex;

    &__size-md {
        border-radius: 50% !important;
        width: 200px;
        height: 200px;
        margin: 0 auto;
        border: solid 5px transparent;
        transition: all .3s;

    }

    &__image {
        flex: 0 0 auto;
        overflow: hidden;
        position: relative;

        .mv-figure {
            padding-top: 70%;

            img {
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
                top: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;
                object-position: center;
                display: block;
                transition: all .5s;
            }
        }

        .mv-badge {
            position: absolute;
            top: 8px;
            left: 8px;
            z-index: 1;
        }
    }

    &__body {
        flex: 1 1 auto;

        > :last-child {
            margin-bottom: 0;
        }
    }

    &__footer {
        flex: 0 0 auto;

        hr {
            margin: 0 0 8px;
        }
    }

    .mv-card__title {
        font-weight: 600;
        color: $secondary;
    }

    .mv-card__title a {
        color: inherit;

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    &:hover,
    &:focus {
        .mv-card__image .mv-figure img {
            transform: scale(1.4);
        }
    }
}

.mv-card--property {
    flex-direction: column;

    .mv-card__price {
        margin-bottom: 8px;

        strong {
            font-weight: 600;
        }

        small {
            color: var(--mv-primarytextlight);
        }
    }

    .mv-card__body {
        flex: 1 1 auto;
        min-height: auto;
        padding: 24px 8px;
    }

    .mv-card__title {
        font-size: $h5-font-size;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &:not(.mv-card--property--fill) {
        .mv-card__text {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .mv-card__footer {
        padding: 0 8px;
    }
}

.mv-card--property--fill {
    flex-direction: row;
    background: #F3F9FF;
    padding: 12px;
    border-radius: 8px;
    gap: 24px;

    .mv-card__image {
        width: 200px;
    }

    .mv-card__body {
        padding: 0;
        align-self: center;
    }

    .mv-card__moredetails {
        display: flex;
        align-items: flex-end;
    }

    .mv-card__price {
        margin: 0;
    }

    .mv-card__text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .mv-card__moredetails__link {
        color: $link-color;
        display: inline-flex;
        align-items: center;
        font-weight: 600;
        margin-left: auto;
        font-size: rem(16px);

        &:hover,
        &:focus {
            text-decoration: underline;
        }
    }

    .mv-card__moredetails__label {
        color: var(--mv-primarytextlight);
        font-weight: 600;
        font-size: rem(16px);
    }
}

.mv-card--highlightednews,
.mv-card--popularnews {
    border-radius: 8px;
    padding: 12px;
    gap: 12px;

    .mv-time {
        margin-bottom: 4px;
        display: block;
    }

    .mv-card__title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.mv-card--highlightednews {
    flex-direction: column;
    background-color: var(--mv-primarytextlighter);
    overflow: hidden;

    .mv-card__text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .mv-card__image {
        width: 100%;

        .mv-figure {
            padding-top: 50%;
            overflow: hidden;
        }
    }
}

.mv-card--popularnews {
    background-color: var(--mv-primarytextlighter);
    position: relative;
    overflow: hidden;
    flex: 1 1 auto;
    min-height: 0;

    .mv-card__body {
        order: 2;
    }

    .mv-card__image {
        width: 90px;
        height: 60px;
        display: block;
        order: 1;
    }

    .mv-card__title {
        font-size: $h5-font-size;
    }

    .mv-card__text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &.mv-card--sm {
        .mv-card__image {
            width: 80px;
        }

        .mv-card__title {
            font-size: rem(16px);
        }

        .mv-card__text {
            font-size: rem(14px);
            line-height: normal;
        }

        .mv-time {
            margin-bottom: 4px;
            font-size: 12px;
            margin: 0;
        }
    }
}

.mv-card--testimonial {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    background: linear-gradient(180deg, #FFF 81.77%, rgba(255, 255, 255, 0.00) 100%);
    padding: 12px;

    &::before {
        opacity: .4;
    }

    &__client {
        display: flex;
        gap: 12px;
    }

    &__avatar {
        width: 40px;
        height: 40px;
        border-radius: 12px 12px 12px 4px;
        overflow: hidden;
        margin: 0;

        .img-fluid {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &__username {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-width: 0;
        justify-content: center;
    }

    &__rating {
        text-align: right;
    }

    &__body {
        display: flex;
        gap: 24px;
    }

    &__content {
        margin: 0;

        p {
            font-size: rem(16px);

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__icon {
        display: none;
    }
}

.mv-card--featured {
    background-color: #1B3A6E;
    background-image: url(../../img/featured-bg.jpg);
    background-position: right bottom;
    background-repeat: no-repeat;
    border-radius: 8px;
    overflow: hidden;
    flex-direction: column;

    .mv-figure {
        border-radius: 0;
    }

    .mv-card__body {
        flex: 1;
        padding: 24px;
        color: $white;
        align-items: flex-start;
        justify-content: center;
        display: flex;
        flex-direction: column;
    }

    .mv-card__title {
        color: $white;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    .mv-card__image .mv-figure img {
        transform: none !important;
    }
}

.mv-card--searchbanner {
    padding: rem(60px) rem(40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    background-image: url('./../img/sale-banner.jpg');
    background-position: right bottom;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;

    &::before {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        background: linear-gradient(90deg, $secondary 0%, rgba($secondary, 0.9) 66%, rgba($secondary, 0) 100%);
        z-index: 0;
        border-radius: 8px;
    }

    .mv-body {
        max-width: 628px;
        text-align: center;
        position: relative;
        z-index: 1;
    }

    h1,
    p {
        color: $white;
    }
}

.mv-rent-bg {
    background-image: url('./../img/rent-banner.jpg');
    // &::before {
    //     background: linear-gradient(90deg, #8C4816 0%, rgb(31, 62, 114) 66%, rgba(31, 62, 114, 0) 100%);
    // }
}

.mv-card--agent {
    flex-direction: column;
    padding: 20px;

    &:hover {
        .mv-card__size-md {
            border: solid 5px $primary;
            transition: all .3s;
        }
    }
}

@include media-breakpoint-up(md) {

    .mv-card--highlightednews,
    .mv-card--popularnews {
        padding: 16px;
        gap: 20px;
    }

    .mv-card--popularnews {
        .mv-card__image {
            width: 120px;
            height: 80px;
        }
    }

    .mv-card--featured {
        flex-direction: row;
        margin: 0 auto;

        .mv-card__image {
            width: rem(400px);
            padding-top: 0;
        }

        .mv-figure {
            border-radius: 8px 0 0 8px;
        }

        .mv-card__body {
            padding: 24px 40px;
        }
    }

    .mv-card--testimonial {
        &__avatar {
            width: 48px;
            height: 48px;
            border-radius: 16px 16px 16px 4px;
        }

        &__client {
            gap: 16px;
        }

        &__icon {
            display: block;

            .icon {
                width: rem(32px);
                height: rem(32px);
            }
        }
    }
}

@include media-breakpoint-up(lg) {
    .mv-card--testimonial {
        padding: 24px;
    }
}

@include media-breakpoint-up(xl) {
    .mv-card--popularnews {
        .mv-card__image {
            width: 180px;
            height: auto;
            border-radius: 8px;
            align-self: flex-start;

            .mv-figure {
                padding-top: 70%;
            }
        }

        .mv-card__text {
            font-size: $font-size-sm;
        }
    }

    .mv-card--property--fill {
        padding: 24px;
        gap: 40px;

        .mv-card__image {
            width: 300px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .mv-card--searchbanner {
        min-height: auto;
        padding: 16px;
    }

    .mv-card--searchbanner p {
        display: none;
    }
  
    .mv-a__service-section {
        margin: 70px 0 70px !important; 
    }
    .img-right-align {
        float: initial;
        margin: 0px 0px 32px 0px;
        border-radius: 12px 50px 12px 50px;
        max-width: 100%;
    }
}


@include media-breakpoint-down(sm) {
    .mv-card--property--fill {
        flex-direction: column;

        .mv-card__image {
            width: 100%;
        }

        .mv-figure-thumbnails {
            justify-content: flex-start;
        }

        .mv-figure-thumbnails .mv-figure-thumbnail,
        .mv-figure-thumbnail-count {
            width: 48px;
        }
    
    }



  
}