.mv-a--sell,
.mv-a--propertyworth {
    .mv-a__layout {
        align-items: center;
    }
}

.mv-a--marketappraisal {
    .mv-a__layout {
        border-radius: 16px 160px 16px 80px;
        border: 16px solid rgba(255, 191, 120, 0.10);
        background: #FFF7EF;
        overflow: hidden;
        gap: 0;
    }

    .mv-a__content {
        display: flex;
        flex-direction: column;
        align-self: center;
        padding: 40px;

        > :last-child {
            margin-bottom: 0;
        }
    }

    .mv-a__image {
        display: flex;
        align-items: center;
        background: #f4e2d1;
        max-width: none;
        width: 100%;
        justify-content: center;
    }

    .tag-img {
        margin: 0;
        max-width: 400px;
    }
}

.mv-a--freemarketappraisal {
    &__title {
        padding: 0 40px 0 0;
        font-size: rem(40px);
        line-height: normal;
        font-style: normal;
        font-weight: 600;
        opacity: .2;
        line-height: normal;
    }

    &__text {
        padding: 0 40px;
        border-left: 1px solid $border-color;
    }

    .mv-a__image {
        max-width: 100%;
        border-radius: 8px;
        overflow: hidden;
    }
}

.mv-formcard {
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0px 40px 40px 0px rgba(0, 0, 0, 0.05);
    padding: rem(16px);
}

.mv-formcard-body {
    background-color: $white;
    border-radius: 8px;
    padding: rem(40px);
}

@include media-breakpoint-down(lg) {
    .mv-a--marketappraisal {
        .mv-a__layout {
            border-radius: 16px 80px 16px 40px;
            border: 8px solid rgba(255, 191, 120, .1);
        }

        .mv-a__content {
            padding: 24px;
        }
    }

    .mv-a--freemarketappraisal {
        &__title {
            padding: 0;
            width: 100%;
            margin-bottom: 16px;

            br {
                display: none;
            }
        }

        &__text {
            padding: 0;
            border-left: none;
        }

        .mv-a__layout {
            gap: 0;
        }
    }

    .mv-a--sell .mv-a__layout {
        flex-direction: column-reverse;
    }

    .mv-a--sell .mv-a__image {
        max-width: 100%;
        width: 100%;
    }

    .mv-formcard-body {
        padding: 24px;
    }
}