.mv-news-detail {
    &__layout {
        display: flex;
        gap: 40px;
        align-items: flex-start;
    }

    &__sidebar {
        display: none;

        .mv-a--news__popular {
            gap: 24px;
        }

        .mv-card--popularnews {
            padding: 0;
            background: transparent;
        }

        .mv-a--news__popular {
            grid-template-columns: auto !important;
        }

        .mv-card--popularnews {
            gap: 16px;
        }
    }

    &__content {
        .mv-link {
            display: inline-flex;
            align-items: center;
        }
        h1 {
            @extend .h2;
            margin-top: 0!important;
        }
        h2 {
            @extend .h3;
            margin-bottom: rem(24px);
        }
        * + h2 {
            margin-top: rem(24px);
        }

        // Blockquote CSS
blockquote {
    border-left: 3px solid $secondary;
    font-size: 1.25em;
    font-style: italic;
    line-height: 1.8em;
    padding: rem(16px) rem(40px);
    position: relative;
    transition: .2s border ease-in-out;
    z-index: 0;

    &:before {
        content: "";
        position: absolute;
        top: 50%;
        left: -4px;
        height: 2em;
        background-color: $white;
        width: 5px;
        margin-top: -1em;
    }

    &:after {
        content: "”";
        position: absolute;
        top: 50%;
        left: -2px;
        color: #1f3e72;
        text-align: center;
        text-indent: -8px;
        transform: scale(2.5) translateY(-50%);
        margin-top: 40px;
    }

    cite {
        display: block;
        font-size: 0.75em;
        line-height: 1.8em;
        margin-top: 1em;
    }
}
    }
   
}
.mv-a {
    &__news-title {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right:0;
        background-color: rgba(#000 , .8);
        display: flex;
        align-items: center;
        padding: 0px 60px;
        h1 {
            color: #fff;
            font-weight: bold;
        }
    }
    &__img-max-w {
        max-width: 50%;
    }
    &__loop {
        margin-bottom: 16px;
        border-bottom: dashed 1px #ccc;
        padding-bottom: 16px;
    }
}

.mv-author-info {
    &__title {
        a {
            color: $secondary;
        }
    }

    &__image {
        flex: 0 0 48px;
    }

    .mv-figure {
        border-radius: 4px;
    }

    &__subtitle {
        color: $secondary;
        display: block;
    }

    &__text {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &__layout {
        display: flex;
        gap: 16px;
    }
}

.mv-news-reply,
.mv-author-info {
    padding: 16px;
    border-radius: rem(4px);
    background-color: var(--mv-primarytextlighter);
}

.mv-news-reply,
.mv-author-info,
.mv-tags {
    margin-top: rem(40px);
}

.mv-news-reply {
    &.center {
        max-width: 920px;
        margin: 0 auto;
    }
}

.mv-post-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 4px 0;
}

@include media-breakpoint-up(sm) {
    .mv-author-info__layout {
        gap: 24px;
    }

    .mv-author-info {
        &__image {
            flex: 0 0 rem(120px);
        }
    }
}

@include media-breakpoint-up(lg) {
    .mv-news-detail {
        &__sidebar {
            flex: 0 0 auto;
            position: sticky;
            top: 90px;
            display: block;
            max-width: 320px;
            min-width: 0;
        }
    }

    .mv-news-reply,
    .mv-author-info {
        padding: 24px;
        border-radius: rem(8px);
    }
}

@include media-breakpoint-up(xxl) {
    .mv-news-detail {
        &__sidebar {
            max-width: 400px;
        }
    }

    .mv-news-detail__layout {
        gap: 80px;
    }

    .mv-card--popularnews.mv-card--sm .mv-card__image {
        width: 96px;
    }
}