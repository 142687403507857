.mv-list {
    @extend .list-unstyled;
}

.mv-list--img {
    display: flex;
    flex-direction: column;
    gap: 40px;
    &__item {
        display: flex;
        gap: 24px;
        padding-left: 12px
    }

    .icon {
        flex: 0 0 auto;
    }

    h3 {
        @extend .h5;
        font-weight: 600;
    }

    p {
        @extend small;
        margin: 0;
    }
}

.mv-list--highlights {
    display: flex;
    gap: rem(24px);
    margin-bottom: 0;

    &__item {
        font-weight: 600;
        color: var(--mv-primarytextlight);
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: rem(16px);
    }
}