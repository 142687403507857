.mv-c-footer {
    display: block;
    background-color: $secondary;
    background-image: url('./../img/footer-bg.jpg');
    background-position: left top;
    background-repeat: no-repeat;
    padding: rem(80px) 0 0;

    .mv-c-footer__layout {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h2,
        p {
            color: $white;
            max-width: 640px;
        }
    }

    .mv-c-nav__link {
        color: $white;
        height: auto;
        padding-top: 0;
        padding-bottom: 0;

        &.active {
            border: none;
            color: $primary;
        }
    }

    .mv-c-nav__item {
        border-right: 1px solid $border-color;

        &:last-child {
            border-right: none;
        }
    }

    .navbar-brand {
        margin: rem(40px) 0 rem(80px);
    }

    .mv-c-nav {
        flex-wrap: wrap;
        gap: 12px 0;
    }
}

.mv-copyright {
    background-color: $white;

    p {
        margin: 0;
    }

    &__layout {
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

@include media-breakpoint-down(lg) {
    .mv-c-footer {
        padding-top: 40px;

        .navbar-brand {
            margin: 24px 0 40px;
        }
    }

    .mv-copyright {
        &__layout {
            flex-direction: column;
            padding: 16px 0;
            height: auto;
            gap: 8px;
        }
    }
}