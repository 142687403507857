.mv-a {
    &__list-content {
li {
    margin-bottom: 32px;
   list-style: disc;
   h3 {
    font-weight: bold;
    font-size: rem(24px);
   }
}
    }
    &__has-bg {
        background-image: url(../img/realestate.jpg);
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        position: relative;
        min-height: 300px;
        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(#000 , .7);
            z-index: 1;

        }
        > * {
            position: relative;
            z-index: 2;
        }
        &:before {
display: none;
        }
      
    }
    &__spec {
        padding: 0;
        margin: 0;
        display: inline-flex;
        gap: 16px;
        flex-wrap: wrap;
        li {
           list-style: none;
           padding: 8px 24px;
           border: solid 1px #ccc;
           border-radius: 20px;
           display: inline-block;
           vertical-align: middle;
           background-color: #fdfdfd;
           transition: all .3s;
           span {
            margin-right: 12px;
            color: $primary;
           }
           &:hover {
            border-color: $primary;
            box-shadow: 0px 0px 30px $primary;
            transition: all .3s;
           }
        }
    }
    &__italic {
        font-style: italic; 
        color: #000;
        p {
            font-style: normal;
        }
    }
    &__map-container {
        .mv-a {
            &__map-inner {
                border-radius: 12px 50px 12px 50px;
                overflow: hidden;
                border: solid 5px $primary;
                margin-bottom: 32px;
                iframe {
                    width: 100%;
                }
            }
        }
       
    }
    &__agent-details {
        padding: 0 32px;
        ul {
            padding: 0;
            li {
                list-style: none;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    &__user {
        font-size: rem(18px);
        margin: 16px 0;
        span {
            font-size: rem(16px);
        }
    }
    &__line-clamp--3 {
        display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    }
    &__custom-card-shadow {
        box-shadow: 0px 0px 29px rgba(#000 , .1);
    }
    &__flex-gap {
        gap: 16px;
    }
}

@include media-breakpoint-down(md) {
.mv-a {
    &__agent-details {
        padding: 0;
    }
    &__flex-wrap {
        flex-wrap: wrap;
    }
}
}