.mv-a--meetagents {
    .mv-a__layout {
        align-items: center;
    }

}

.mv-a--agents {
    .mv-a__header {
        text-align: center;
        margin-bottom: 0;
    }

    .mv-card__image {
        margin-bottom: rem(16px);

        .mv-figure {
            padding-top: 115.25%;
            border-radius: rem(60px) rem(60px) rem(60px) rem(120px);
        }
    }

    .mv-card__title {
        margin-bottom: 0;
    }

    .mv-card__subtitle {
        color: $secondary;
    }

    .mv-card__text {
        margin-top: rem(16px);
        font-size: rem(14px);
        margin-bottom: rem(24px);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
    }
}

// @include media-breakpoint-down(lg) {
//     .mv-a--meetagents {
//         text-align: center;
//     }
// }