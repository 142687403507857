.mv-number-counter-group {
    display: flex;
    flex-direction: column;
    gap: 12px;

}

.mv-number-counter {
    display: flex;
    align-items: center;

    .mv-number-counter__control {
        margin-left: auto;
    }
}

.mv-number-counter__label {
    font-size: rem(16px);
}

.mv-number-counter__control {
    display: flex;
    align-items: center;

    input {
        width: 32px;
        border: none;
        text-align: center;
        box-shadow: none;
        outline: none;
        color: $secondary;
    }

    .mv-number-counter__minus,
    .mv-number-counter__plus {
        width: 20px;
        height: 20px;
        border-radius: 100%;
        border: 1px solid $secondary;
        background: none;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $secondary;

        &:hover,
        &:focus {
            background-color: $secondary;
            color: $white;
        }
    }
}