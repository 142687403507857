.mv-a--contact {
    &__body {
        padding: 24px 0 0;
    }

    &__layout {
        display: flex;
        gap: 24px;
        flex-direction: column;
    }

    &__content {
        >*:last-child {
            margin-bottom: 0;
        }
    }
}

.mv-address {
    @extend .list-unstyled;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 24px;
    background: var(--mv-primarytextlighter);
    border-radius: 8px;

    >* {
        display: flex;
        align-items: flex-start;
        gap: 16px;
    }

    .icon {
        color: $secondary;
        margin-top: rem(4px);
        flex: 0 0 auto;
    }
}

@include media-breakpoint-up(lg) {
    .mv-a--contact {
        &__body {
            padding: 40px 0 0;
            max-width: 1120px;
            margin: 0 auto;
        }

        &__layout {
            flex-direction: row;
            gap: 60px;
        }

        &__address {
            flex: 0 0 420px;
        }
    }

    .mv-address {
        gap: 16px;
    }
}

@include media-breakpoint-up(xxl) {
    .mv-a--contact {
        &__layout {
            gap: 80px;
        }
    }
}