.mv-s--sendmsg {
    margin-bottom: 20px;

    &__layout {
        display: flex;
        background: var(--mv-primarytextlighter);
        padding: 16px;
        border-radius: 4px;
        gap: 24px;
        flex-direction: column;

    }

    &__content {
        flex: 1;
        min-width: 0;
    }

    &__address {
        flex: 1;
        min-width: 0;
    }
}

.mv-map {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;

    iframe {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@include media-breakpoint-up(lg) {
    .mv-s--sendmsg {
        margin-bottom: 80px;

        &__layout {
            flex-direction: row;
        }
    }
}

@include media-breakpoint-up(xl) {
    .mv-s--sendmsg {
        &__layout {
            padding: 40px;
            border-radius: 8px;
            gap: 40px;
        }
    }
}