.mv-filter-group {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.mv-filter {
    color: $secondary;
    height: rem(32px);
    background-color: #f3f9ff;
    border-radius: rem(32px);
    border: 1px solid $border-color;
    padding: 4px 16px;
    display: inline-flex;
    align-items: center;
    font-size: rem(14px);
    white-space: nowrap;
    overflow: hidden;
    span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }

    &__close {
        color: var(--mv-primarytextlight);
        margin-left: 4px;
        height: rem(16px);
        width: rem(16px);
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: rem(-8px);

        .icon {
            display: block;
            transform: rotate(45deg);
            backface-visibility: hidden;
        }
        &:hover,
        &:focus {
            color: $danger;
        }
    }

    &:hover,
    &:focus {
        background-color: var(--mv-primarytextlighter);
    }
}

.mv-selected-title {
    display: flex;
    align-items: center;
    gap: 16px;
    .mv-link {
        margin-left: auto;
    }
}